<template v-if="this.hasView === true">
  <CRow>
    <CCol md="6">
      <CCard>
        <CCardHeader>
          <CButton
            v-if="this.hasPass === true"
            color="info"
            class="mr-1"
            shape="pill"
            @click="goDirection('change-password')"
          >{{$t("PROFILE_PAGE_BUTTON_CHANGE_PASSWORD")}}</CButton>
          <CButton
            v-if="this.hasHistory === true"
            color="info"
            class="mr-1"
            shape="pill"
            @click="goDirection('login-history')"
          >{{$t("PROFILE_PAGE_BUTTON_LOGIN_HISTORY")}}</CButton>
        </CCardHeader>
        <CCardBody>
        <ValidationObserver ref="observer"  @submit.prevent="updateName(names)">
          <ValidationProvider :name="$t('PROFILE_PAGE_FIELD_NAME_VALIDATE_NAME')" rules="required" v-slot="{ errors }">
            <div>
              <CInput
                :label="$t('PROFILE_PAGE_LABEL_NAME')"
                horizontal
                class="form-group--custom mb-10"
                v-model="names"
              />
              <CRow class="form-err">
                  <CCol :sm="{offset:'3'}"></CCol>
                  <CCol :sm="9">
                      <p class="error-msg">{{errors[0]}}</p>
                  </CCol>
              </CRow>
            </div>
          </ValidationProvider>
          </ValidationObserver>
          <!-- <CInput label="Email" :value="email" type="email" horizontal autocomplete="email" disabled /> -->
          <div class="form-group--custom form-group form-row">
            <label class="col-sm-3">{{$t("PROFILE_PAGE_LABEL_EMAIL")}}</label>
            <h6 class="col-sm-9"><strong>{{ email }}</strong></h6>
          </div>
          <CRow v-if="this.has2FA === true">
            <CCol md="3">
              <h6 class="mt-1">{{$t("PROFILE_PAGE_LABEL_2FA")}}</h6>
            </CCol>
            <CCol md="9">
              <CSwitch
                color="success"
                @change.native="check(checked2fa)"
                :checked="checked2fa"
                variant="3d"
                v-bind="labelIcon"
              />
            </CCol>
          </CRow>
          <CRow class="btn-save float-right" v-if="this.hasUpdate === true">
            <!-- <CButton v-if="isCreate" @click="openWarningLeaveModal" class="btn-save--cancel">Cancel</CButton> -->
            <CButton @click="updateName(names)" >
              {{$t("PROFILE_PAGE_BUTTON_SAVE")}}
            </CButton>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      labelIcon: {
        labelOn: "\u2713",
        labelOff: "\u2715"
      },
      names: '',
      hasView: false,
      hasPass: false,
      hasHistory: false,
      hasUpdate: false,
      has2FA:false
    };
  },
  computed: {
    ...mapState({
      checked2fa: state => state.auth.twofaEnableFlag,
      email: state => state.auth.userEmail,
      name: state => state.auth.userName,
      userPermission: state => state.auth.userPermission,
    })
  },
  methods: {
    ...mapActions(['updateProfileName']),
    goDirection(value) {
      this.$router.push(`profile/${value}`);
    },
    check(value) {
      this.$router.push("profile/setting-2fa");
    },
    async updateName(e){
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      let data = {
        name: e
      };
      await this.updateProfileName(data);
    },
    checkPermission(){
      if(this.userPermission.length > 0){
         this.userPermission.forEach(items =>{
             if(items.name === 'VIEW_ACCOUNT'){
                this.hasView = true
             }
             if(items.name === 'CHANGE_PASSWORD_ACCOUNT'){
                this.hasPass = true
             }
             if(items.name === 'VIEW_LOGIN_HISTORY_ACCOUNT'){
                 this.hasHistory = true
             }
             if(items.name === 'UPDATE_PROFILE_ACCOUNT'){
                 this.hasUpdate = true
             }
             if(items.name === 'UPDATE_2FA_ACCOUNT'){
                 this.has2FA = true
             }
        })
      }
    }
  },
  async mounted(){
    this.names = this.name;
    await this.checkPermission();
  }
};
</script>
<style lang="scss">
.btn-save{
  padding-right: 50px;
  @media only screen and ( max-width: 1024px ){
    padding-right: 0;
  }
  .btn{
    min-width: 146px;
    height: 36px;
    border-radius: 4px;
    background-color: #3B4B7E;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    border: none;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-align: center;
  }
  .btn-save--cancel{
    border: none;
    background: unset;
    color: #657187;
    // font-family: "SF UI Display";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 35px;
    margin-right: 50px;
    &:hover,&:visited,&:focus{
      outline: none;
      box-shadow: none;
    }
    @media only screen and ( max-width: 1024px ){
      margin-right: 20px;
    }
  }
}
.form-group--custom{
  h6{
    margin: 0;
    strong{
      line-height: 22px;
    }
  }
  &.mb-10{
    margin-bottom: 10px;
  }
}
.form-err{
  .col-sm-9{
    padding-left: 10px;
    .error-msg{
      color: #e91717;
    }
  }
}
</style>
